import React from 'react';

import ThorgateLogo from 'images/icons/thorgate-logo-no-text.svg';
import FacebookIcon from 'images/icons/facebook.svg';
import TwitterIcon from 'images/icons/twitter.svg';
import LinkedInIcon from 'images/icons/linkedin.svg';

import './Footer.scss';

const Footer = () => (
    <footer className="footer">
        <div className="container footer__container">
            <div className="footer__left">
                <ThorgateLogo className="footer__logo" />©{' '}
                {new Date().getFullYear()} Thorgate
            </div>
            <div className="footer__right">
                <ul className="footer__links">
                    <li className="footer__link">
                        <a href="https://thorgate.eu/">Thorgate</a>
                    </li>
                    <li className="footer__link">
                        <a href="https://design.thorgate.eu/">Design</a>
                    </li>
                    <li className="footer__link">
                        <a href="http://thorgateventures.com/">Ventures</a>
                    </li>
                    <li className="footer__link">
                        <a href="http://thorgate.github.io/">Labs</a>
                    </li>
                    <li className="footer__link">
                        <a href="https://jobs.thorgate.eu/">Jobs</a>
                    </li>
                    <li className="footer__link">
                        <a href="https://pythonestonia.ee/">PythonEstonia</a>
                    </li>
                </ul>
                <ul className="footer__social-links">
                    <li className="footer__social-link">
                        <a href="https://www.facebook.com/thorgate">
                            <FacebookIcon />
                        </a>
                    </li>
                    <li className="footer__social-link">
                        <a href="https://twitter.com/thorgate">
                            <TwitterIcon />
                        </a>
                    </li>
                    <li className="footer__social-link">
                        <a href="https://www.linkedin.com/company/thorgate">
                            <LinkedInIcon className="footer__icon--linkedin" />
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </footer>
);

export default Footer;
