import React, {useState} from 'react';
import {Formik, Form, Field} from 'formik';
import queryString from 'querystring';

import Input from './Input';
import ValidationSchema from './ValidationSchema';
import './SubmissionForm.scss';

const SubmissionForm = () => {
    const [submitted, setSubmitted] = useState(false);
    if (submitted) {
        return (
            <div className="section-gray">
                <div id="form" className="container">
                    <div className="row section-row">
                        <div className="col-12 text-center">
                            <p>
                                Thanks for your interest in Thorgate&apos;s
                                affiliate program, we&apos;ll get in touch with
                                you shortly.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="section-gray">
            <div id="form" className="container">
                <div className="row section-row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <h1>Let&apos;s succeed together!</h1>
                        <p>
                            Join our affiliate program, help us meet companies
                            and digitalize operations. Work{' '}
                            <strong>remotely</strong> at your own pace and{' '}
                            <strong>earn money!</strong>
                        </p>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <Formik
                            initialValues={{
                                subject: 'New form submission: Affiliates Page',
                                name: '',
                                email: '',
                                phoneNumber: '',
                                linkedinProfile: '',
                            }}
                            validationSchema={ValidationSchema}
                            onSubmit={values => {
                                fetch('/', {
                                    method: 'POST',
                                    headers: {
                                        'Content-Type':
                                            'application/x-www-form-urlencoded',
                                    },
                                    body: queryString.stringify({
                                        'form-name': 'submission',
                                        ...values,
                                    }),
                                })
                                    .then(() => setSubmitted(true))
                                    .catch(error => console.error(error));
                            }}
                        >
                            {({isSubmitting}) => (
                                <Form
                                    name="submission"
                                    data-netlify="true"
                                    data-netlify-honeypot="bot-field"
                                    className="submission-form"
                                >
                                    <input name="subject" type="hidden" />
                                    <Input
                                        name="name"
                                        placeholder="Full name *"
                                    />
                                    <Input
                                        name="email"
                                        placeholder="Business email *"
                                    />
                                    <Input
                                        name="phoneNumber"
                                        placeholder="Phone number *"
                                    />
                                    <Input
                                        name="linkedinProfile"
                                        placeholder="Linkedin Profile*"
                                    />
                                    <Field
                                        name="form-name"
                                        value="submission"
                                        type="hidden"
                                    />
                                    <button
                                        type="submit"
                                        disabled={isSubmitting}
                                    >
                                        Submit
                                    </button>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SubmissionForm;
