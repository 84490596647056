import * as Yup from 'yup';

export default Yup.object().shape({
    name: Yup.string()
        .max(100, 'Name is too long!')
        .required('This field is required'),
    email: Yup.string()
        .email('Invalid email')
        .required('This field is required'),
    phoneNumber: Yup.string()
        .max(20, 'Phone number is too long!')
        .required('This field is required'),
    linkedinProfile: Yup.string().required('This field is required'),
});
