import React from 'react';
import PropTypes from 'prop-types';
import {ErrorMessage, Field} from 'formik';

const Input = ({name, label = null, ...rest}) => (
    <div className="input-block">
        {label && <label htmlFor={name}>{label}</label>}
        <Field id={name} name={name} {...rest} />
        <ErrorMessage name={name} component="div" className="error" />
    </div>
);

Input.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
};

export default Input;
